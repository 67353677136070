import { authClient } from "client/authFetchClient";
import { useLocalStorage } from "hooks/useLocalStorage";
import React, { useEffect, useMemo, useReducer } from "react";
import { ISchool, IUser } from "types/entities";
import { RoleName } from "types/types";

type State = {
  token: string;
  user: IUser;
  hasRegistered: boolean;
  hasRegisteredAsEmployee: boolean;
  role: RoleName;
  school?: ISchool;
};

type Action =
  | {
      type: "login";
      token: string;
      user: IUser;
      role: RoleName;
      school?: ISchool;
    }
  | { type: "logout" }
  | { type: "setRegistered"; hasRegistered: boolean }
  | { type: "setRegisteredAsEmployee"; hasRegisteredAsEmployee: boolean };
type Dispatch = (action: Action) => void;

const AuthContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

function authReducer(state: State, action: Action) {
  switch (action.type) {
    case "login": {
      return {
        ...state,
        token: action.token,
        user: action.user,
        role: action.role,
        school: action.school,
      };
    }
    case "logout": {
      return { ...state, token: "", user: {} as IUser };
    }
    case "setRegistered": {
      return { ...state, hasRegistered: action.hasRegistered };
    }
    case "setRegisteredAsEmployee": {
      return {
        ...state,
        hasRegisteredAsEmployee: action.hasRegisteredAsEmployee,
      };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
}

const AuthProvider: React.FunctionComponent = ({ children }) => {
  const [persistedState, setPersistedState] = useLocalStorage<State>("state", {
    token: "",
    user: {} as IUser,
    hasRegistered: false,
    hasRegisteredAsEmployee: false,
    role: "Candidato" as RoleName,
    school: undefined,
  });
  const [state, dispatch] = useReducer(authReducer, persistedState);

  useEffect(() => {
    setPersistedState(state);
  }, [setPersistedState, state]);

  const value = useMemo(() => ({ state, dispatch }), [state]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

function useAuth() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
}

function useClient() {
  const {
    state: { token },
    dispatch,
  } = useAuth();

  return React.useCallback(
    () => authClient(token, () => dispatch({ type: "logout" })),
    [dispatch, token]
  )();
}

export { AuthProvider, useAuth, useClient };
