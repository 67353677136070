import { Ability, AbilityBuilder, AnyMongoAbility } from "@casl/ability";
import { IUser } from "types/entities";

export function updateAbility(ability: AnyMongoAbility, user: IUser) {
  const { can, rules } = new AbilityBuilder(Ability);
  user?.roles[0].permissions.forEach((permission) => {
    // Los permisos del backend vienen en el formato "acción_entidad" Ej: "read_users"
    const [action, subject] = permission.name.split("_");
    can(action, subject);
  });
  ability.update(rules);
}
