import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "./full-page-spinner.scss";

const FullPageSpinner = () => {
  const antIcon = <LoadingOutlined style={{ fontSize: 100 }} spin />;

  return (
    <div className="fullpage-spinner-wrapper">
      <Spin indicator={antIcon} />
    </div>
  );
};

export default FullPageSpinner;
