import { createContext, useContext, useEffect } from "react";
import { createContextualCan } from "@casl/react";
import { defineAbility } from "@casl/ability";
import { useLocalStorage } from "hooks/useLocalStorage";
import { updateAbility } from "utils/updateAbility";
import { IUser } from "types/entities";

export const Ability = defineAbility(() => {});

export const AbilityContext = createContext(Ability);
export const Can = createContextualCan(AbilityContext.Consumer);

const AbilityProvider: React.FunctionComponent = ({ children }) => {
  const [persistedState] = useLocalStorage("state", {
    user: {} as IUser,
    token: "",
  });

  useEffect(() => {
    if (persistedState.user && persistedState.token) {
      updateAbility(Ability, persistedState.user);
    }
  }, [persistedState.token, persistedState.user]);

  return (
    <AbilityContext.Provider value={Ability}>
      {children}
    </AbilityContext.Provider>
  );
};

function useAbilityContext() {
  const context = useContext(AbilityContext);
  if (context === undefined) {
    throw new Error("useAbility must be used within an Ability Provider");
  }
  return context;
}

export { useAbilityContext, AbilityProvider };
