import axios from "axios";

export function authClient(token: string, logout: () => void) {
  const baseApi = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });

  baseApi.interceptors.request.use(
    (config) => {
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      Promise.reject(error);
    }
  );

  baseApi.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        logout();
      } else {
        return Promise.reject(error);
      }
    }
  );

  return baseApi;
}
