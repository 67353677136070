import * as React from "react";
import FullPageSpinner from "components/FullPageSpinner/FullPageSpinner";
import { useAuth } from "context/AuthContext";

const AuthenticatedApp = React.lazy(
  () => import(/* webpackPrefetch: true */ "./AuthenticatedApp")
);
const UnauthenticatedApp = React.lazy(
  () => import("./components/UnanthenticatedApp/UnauthenticatedApp")
);

const App = () => {
  const { state } = useAuth();
  return (
    <React.Suspense fallback={<FullPageSpinner />}>
      {state.token ? <AuthenticatedApp /> : <UnauthenticatedApp />}
    </React.Suspense>
  );
};

export default App;
