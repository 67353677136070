import React from "react";
import ReactDOM from "react-dom";
import { ConfigProvider } from "antd";
import moment from "moment";
import App from "./App";
import esES from "antd/lib/locale/es_ES";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { AbilityProvider } from "context/PermissionContext/PermissionContext";

import "moment/locale/es";
import "antd/dist/antd.css";
import "./styles/global.scss";
import "./styles/_variables.scss";

moment.locale("es");
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

ReactDOM.render(
  <ConfigProvider locale={esES}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <AbilityProvider>
            <App />
          </AbilityProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </ConfigProvider>,
  document.getElementById("root")
);
